import EStateAsyncStatus from "@/constants/stateAsyncState";
import { AppState } from "@/interfaces/redux";
import { getCategories, getCategoryById } from "@/service/category";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ICategory } from "@/interfaces/category";

export interface IInitialState {
  categoriesList: ICategory[];
  status:
    | EStateAsyncStatus.failed
    | EStateAsyncStatus.idle
    | EStateAsyncStatus.pending
    | EStateAsyncStatus.succeeded;
  subcategoriesList: ICategory[];
}

const initialState: IInitialState = {
  categoriesList: [],
  status: EStateAsyncStatus.idle,
  subcategoriesList: [],
};

const fetchCategories = createAsyncThunk(
  "categories/fetchCategories",
  async () => {
    const res = getCategories();
    return res;
  },
);

const fetchCategoryById = createAsyncThunk(
  "categories/fetchCategoryById",
  async (id: number) => {
    const res = getCategoryById(id);
    return res;
  },
);

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    clearCategoryData: (state) => {
      state.subcategoriesList = [];
      state.status = EStateAsyncStatus.idle;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.status = EStateAsyncStatus.pending;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = EStateAsyncStatus.succeeded;
        state.categoriesList = action.payload.data;
      })
      .addCase(fetchCategories.rejected, (state) => {
        state.status = EStateAsyncStatus.failed;
      });

    builder
      .addCase(fetchCategoryById.pending, (state) => {
        state.status = EStateAsyncStatus.pending;
      })
      .addCase(fetchCategoryById.fulfilled, (state, action) => {
        state.status = EStateAsyncStatus.succeeded;
        state.subcategoriesList = action.payload.data.subCategory;
      })
      .addCase(fetchCategoryById.rejected, (state) => {
        state.status = EStateAsyncStatus.failed;
      });
  },
});

export const { clearCategoryData } = categoriesSlice.actions;
export { fetchCategories, fetchCategoryById };
export const categoriesList = (state: AppState) => state.categories;
export default categoriesSlice.reducer;
