import {
  ILoginInfo,
  ISignUpPayload,
  IThirdPartyLogin,
  ResetPasswordPayload,
  UpdateCustomerInfoPayload,
  UpdatePasswordPayload,
} from "@/interfaces/customer";
import request from "@/utils/request";

const signUp = async (payload: ISignUpPayload) =>
  request({
    method: "POST",
    url: "customer",
    params: {},
    data: payload,
  }).then((response) => response);

const login = async (payload: ILoginInfo) =>
  request({
    method: "POST",
    url: "customer/login",
    params: {},
    data: payload,
  }).then((response) => response);

const thirdPartyLogin = async (payload: IThirdPartyLogin) =>
  request({
    method: "POST",
    url: "customer/login/third",
    params: {},
    data: payload,
  }).then((response) => response);

const getCustomerInfo = async () =>
  request({
    method: "GET",
    url: `customer`,
    params: {},
  }).then((response) => response);

const updateCustomerInfo = async (payload: UpdateCustomerInfoPayload) =>
  request({
    method: "PUT",
    url: `customer/info`,
    params: {},
    data: payload,
  }).then((response) => response);

const updatePassword = async (payload: UpdatePasswordPayload) =>
  request({
    method: "PUT",
    url: `customer/password`,
    params: {},
    data: payload,
  }).then((response) => response);

const sendVerificationEmail = async () =>
  request({
    method: "POST",
    url: "customer/send/verify",
    params: {},
  }).then((response) => response);

const verifyCustomer = async () =>
  request({
    method: "PUT",
    url: "customer/verify",
    params: {},
  }).then((response) => response);

const sendVerificationCode = async (email: string) =>
  request({
    method: "POST",
    url: "customer/send/code",
    params: {},
    data: { email },
  }).then((response) => response);

const verifyCode = async (payload: { email: string; code: string }) =>
  request({
    method: "POST",
    url: "customer/verify/code",
    params: {},
    data: payload,
  }).then((response) => response);

const sendForgotPassword = async (to: string) =>
  request({
    method: "POST",
    url: "customer/forgot/email",
    params: {},
    data: { to },
  }).then((response) => response);

const resetForgotPassword = async (payload: ResetPasswordPayload) =>
  request({
    method: "POST",
    url: "customer/forgot/reset",
    params: {},
    data: payload,
  }).then((response) => response);

export {
  signUp,
  login,
  getCustomerInfo,
  updateCustomerInfo,
  updatePassword,
  sendVerificationEmail,
  verifyCustomer,
  sendVerificationCode,
  verifyCode,
  thirdPartyLogin,
  sendForgotPassword,
  resetForgotPassword,
};
