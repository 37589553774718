import axios, { AxiosRequestConfig } from "axios";
import { TOKEN } from "@/constants/storeLocation";
import { checkCSR } from "./check";
interface IRequest {
  method: "GET" | "POST" | "DELETE" | "PUT" | "PATCH";
  url: string;
  params: Record<string, unknown>;
}

const axiosInstance = axios.create({
  baseURL: "https://priceworth-backend-v2-5e140f5a9354.herokuapp.com",
  withCredentials: true,
  timeout: 30000,
});

axiosInstance.interceptors.response.use(
  (response) => {
    return Promise.resolve(response.data);
  },
  (error) => {
    const { response } = error;
    return Promise.reject(response || error);
  },
);

export default function request(options: AxiosRequestConfig & IRequest) {
  return axiosInstance({
    ...options,
    headers: {
      "Content-Type": "application/json",
      Authorization:
        (checkCSR() && `${localStorage.getItem(TOKEN)?.slice(1, -1)}`) || "",
    },
  })
    .then((response) => response)
    .catch((error) => error);
}
