import { AppState } from "@/interfaces/redux";
import { createSlice } from "@reduxjs/toolkit";

export interface IInitialState {
  isLogin: boolean | "pending";
}

const initialState: IInitialState = {
  isLogin: "pending",
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    login(state) {
      state.isLogin = true;
    },
    signOut(state) {
      state.isLogin = false;
    },
  },
});

export const { login, signOut } = loginSlice.actions;
export const loginInfo = (state: AppState) => state.login;
export default loginSlice.reducer;
