import {
  AddItemToCartPayload,
  DeleteItemFromCartPayload,
  UpdateItemFromCartPayload,
} from "@/interfaces/cart";
import request from "@/utils/request";

const createGuestCart = async () =>
  request({
    method: "POST",
    url: "cart",
    params: {},
  }).then((response) => response);

const addItemToCart = async (payload: AddItemToCartPayload) =>
  request({
    method: "POST",
    url: "cart/items/add",
    params: {},
    data: payload,
  }).then((response) => response);

const getCartById = async (id: string) =>
  request({
    method: "GET",
    url: `cart/${id}`,
    params: {},
  }).then((response) => response);

const deleteItemFromCart = async (payload: DeleteItemFromCartPayload) =>
  request({
    method: "DELETE",
    url: "cart/items/delete",
    params: {},
    data: payload,
  }).then((response) => response);

const updateItemFromCart = async (payload: UpdateItemFromCartPayload) =>
  request({
    method: "PUT",
    url: "cart/items/update",
    params: {},
    data: payload,
  }).then((response) => response);

const emptyCart = async (id: string) =>
  request({
    method: "PUT",
    url: `cart/empty/${id}`,
    params: {},
  }).then((response) => response);

export {
  createGuestCart,
  addItemToCart,
  getCartById,
  deleteItemFromCart,
  updateItemFromCart,
  emptyCart,
};
