import request from "@/utils/request";

const getBlogs = () =>
  request({
    method: "GET",
    url: "/blog",
    params: {},
  }).then((response) => response);

const getBlogById = (id: number) =>
  request({
    method: "GET",
    url: `/blog/${id}`,
    params: {},
  }).then((response) => response);

const getBlogBySlug = (slug: string) =>
  request({
    method: "GET",
    url: `/blog/slug/${slug}`,
    params: {},
  }).then((response) => response);

export { getBlogs, getBlogById, getBlogBySlug };
