import { headerNav } from "@/data/nav";
import Link from "next/link";
import { ImSearch } from "react-icons/im";
import { BiSolidUser } from "react-icons/bi";
import { AiFillShopping } from "react-icons/ai";
import { ICart } from "@/interfaces/cart";
import { isEmpty, isEqual } from "lodash";
import { Badge, Image } from "@nextui-org/react";
import { useAppSelector } from "@/hooks/useRedux";
import { loginInfo } from "@/store/slices/loginSlice";
import { FiMenu } from "react-icons/fi";
import { categoriesList } from "@/store/slices/categorySlice";
import EStateAsyncStatus from "@/constants/stateAsyncState";
import { useState } from "react";

interface HeaderProps {
  cart: ICart | null;
  setCartOpen: (value: boolean) => void;
  setMenuOpen: (value: boolean) => void;
  setSearchOpen: (value: boolean) => void;
}

const Header: React.FC<HeaderProps> = (props) => {
  const { cart, setCartOpen, setMenuOpen, setSearchOpen } = props;
  const [showId, setShowId] = useState<number>();
  const loginState = useAppSelector(loginInfo);
  const category = useAppSelector(categoriesList);
  return (
    <header
      className="sticky top-0 w-full z-40 border-b flex flex-col items-center bg-opacity-95 bg-white select-none"
      onMouseLeave={() => setShowId(0)}
    >
      <div className="flex container justify-center md:hidden">
        <Link href="/" className="w-36">
          <Image src="/logo.webp" alt="logo" width={780} height={356} />
        </Link>
      </div>
      <div className="flex container px-2 items-center justify-between py-5">
        <div className="flex items-center gap-4">
          <div
            className="cursor-pointer w-14 h-14 rounder-full flex justify-center items-center bg-gray-100 rounded-full hover:text-icon hover:bg-gray-200 transition-all duration-500"
            onClick={() => setMenuOpen(true)}
          >
            <FiMenu className="text-2xl" />
          </div>
          <Link href="/" className="w-36 hidden md:block ">
            <Image src="/logo.webp" alt="logo" width={780} height={356} />
          </Link>
        </div>
        <div className="flex items-center">
          <nav className="px-8 hidden 2xl:block">
            <ul className="flex gap-8 text-sm font-semibold">
              {headerNav.map(({ label, link, id }) => {
                return (
                  <div key={id} className="relative">
                    <Link href={link} onMouseEnter={() => setShowId(id)}>
                      <li
                        className={`py-4 border-t ${
                          isEqual(showId, id)
                            ? "border-icon text-icon"
                            : "border-transparent"
                        } transition-all duration-500`}
                      >
                        {label}
                      </li>
                    </Link>
                    {isEqual(category.status, EStateAsyncStatus.succeeded) &&
                      Array.isArray(category.categoriesList) &&
                      category.categoriesList.map((item) => {
                        const { children } = item;
                        if (isEqual(id, item.id)) {
                          return (
                            <ul
                              key={item.id}
                              className={`absolute p-4 bg-white top-20 shadow-card flex-col gap-2 transition-all duration-500
                              ${isEqual(showId, id) ? "flex" : "hidden"}`}
                            >
                              {children.map((item) => {
                                const link = `/category/${item.slug}/product`;
                                return (
                                  <Link
                                    key={item.id}
                                    href={link}
                                    className="hover:text-icon w-56 flex items-center justify-between transition-all duration-500"
                                  >
                                    {item.name}
                                  </Link>
                                );
                              })}
                            </ul>
                          );
                        }
                      })}
                  </div>
                );
              })}
              <Link href="/sale">
                <li
                  className="py-4 border-t text-red-500 border-transparent hover:border-red-500 transition-all duration-500"
                  onMouseEnter={() => setShowId(0)}
                >
                  Sale
                </li>
              </Link>
            </ul>
          </nav>
          <div className="flex gap-2 border-l border-gray-300 px-4">
            <div
              className="w-10 h-10 rounded-full flex justify-center items-center hover:bg-gray-100 transition duration-300 hover:text-icon cursor-pointer"
              onClick={() => setSearchOpen(true)}
            >
              <ImSearch />
            </div>
            <Link
              href={loginState.isLogin ? "/profile" : "/login"}
              aria-label="profile/login&signup"
            >
              <div className="w-10 h-10 rounded-full flex justify-center items-center hover:bg-gray-100 transition duration-300 hover:text-icon cursor-pointer">
                <BiSolidUser />
              </div>
            </Link>
          </div>
          <div className="flex items-center gap-2">
            <div className="font-bold min-w-24 flex justify-end">
              ${cart ? cart.total : "0.00"}
            </div>
            <div
              className="cursor-pointer w-14 h-14 rounder-full flex justify-center items-center bg-gray-100 rounded-full hover:text-icon hover:bg-gray-200 transition-all duration-500"
              onClick={() => setCartOpen(true)}
            >
              {cart && !isEmpty(cart.shoppingCartItems) ? (
                <Badge
                  content={cart.shoppingCartItems.length}
                  className="bg-icon text-white"
                >
                  <AiFillShopping className="text-2xl" />
                </Badge>
              ) : (
                <AiFillShopping className="text-2xl" />
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
