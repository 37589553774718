import { useEffect, useState } from "react";
import { Button } from "@nextui-org/react";
import { BsFacebook } from "react-icons/bs";
import { RiInstagramFill } from "react-icons/ri";
import Link from "next/link";
import { FaArrowUpLong } from "react-icons/fa6";

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 600) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="fixed bottom-24 right-0 z-25 flex items-end gap-3">
        <div
          className={`transform transition-transform duration-500 ease-in-out ${
            isVisible
              ? "translate-x-0 opacity-100"
              : "translate-x-full opacity-0"
          }`}
        >
          <div className="flex flex-col items-center">
            <div
              onClick={scrollToTop}
              className="bg-white cursor-pointer min-w-0 flex flex-col gap-2 px-2 items-center py-2 select-none hover:text-icon shadow-card transition-all duration-500"
            >
              <FaArrowUpLong className="text-2xl" />
              <span className="text-sm">TOP</span>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed bottom-5 right-6 z-50 flex items-end gap-3">
        <Link
          href="https://www.facebook.com/www.priceworth.com.au/"
          target="_blank"
          rel="noreferrer"
          aria-label="Facebook"
        >
          <Button
            className="bg-gray-50 w-[60px] h-[60px] rounded-full min-w-0 hover:text-icon hover:shadow-xl transition-all duration-500"
            aria-label="Facebook"
          >
            <BsFacebook className="text-2xl" />
          </Button>
        </Link>
        <Link
          href="https://www.instagram.com/priceworthfurniture.vic/"
          target="_blank"
          rel="noreferrer"
          aria-label="Instagram"
        >
          <Button
            className="bg-gray-50 w-[60px] h-[60px] rounded-full min-w-0 hover:text-icon hover:shadow-xl transition-all duration-500"
            aria-label="Instagram"
          >
            <RiInstagramFill className="text-2xl" />
          </Button>
        </Link>
        <div className="bg-gray-50 w-[60px] h-[60px] rounded-full min-w-0" />
      </div>
    </>
  );
};

export default BackToTop;
