import { Drawer } from "antd";
import SearchProduct from "@/components/Shares/SearchProduct";

interface MenuDrawerProps {
  searchOpen: boolean;
  setSearchOpen: (value: boolean) => void;
}

const SearchDrawer: React.FC<MenuDrawerProps> = (props) => {
  const { searchOpen, setSearchOpen } = props;

  const onClose = () => {
    setSearchOpen(false);
  };

  return (
    <Drawer placement="top" height={600} open={searchOpen} onClose={onClose}>
      <div className="flex items-center pt-6 flex-col justify-center">
        <SearchProduct resultNumber={5} onClose={onClose} />
      </div>
    </Drawer>
  );
};

export default SearchDrawer;
