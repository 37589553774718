import { toast } from "react-toastify";

export const notify = (message: string, type = "default", autoClose = 2000) => {
  const options = {
    autoClose: autoClose,
  };
  switch (type) {
    case "success":
      toast.success(message, options);
      break;
    case "error":
      toast.error(message, options);
      break;
    case "info":
      toast.info(message, options);
      break;
    case "warning":
      toast.warn(message, options);
      break;
    default:
      toast(message, options);
  }
};
