import request from "@/utils/request";

const getProducts = async () =>
  request({
    method: "GET",
    url: "product/all",
    params: {},
  }).then((response) => response);

const getProductById = async (id: number) =>
  request({
    method: "GET",
    url: `product/${id}`,
    params: {},
  }).then((response) => response);

const getProductBySlug = async (slug: string) =>
  request({
    method: "GET",
    url: `product/slug/${slug}`,
    params: {},
  }).then((response) => response);

const searchProduct = async (str: string) =>
  request({
    method: "GET",
    url: `product/search?str=${str}`,
    params: {},
  }).then((response) => response);

const getProductsOnSale = async () =>
  request({
    method: "GET",
    url: "product/sales",
    params: {},
  }).then((response) => response);

const fuzz = async (query: string) => {
  const response = await fetch(
    `https://wy23oa6r39.execute-api.ap-southeast-2.amazonaws.com/prod/search/fuzz?q=${encodeURIComponent(
      query,
    )}`,
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await response.json();
  return data;
};

const getProductTitleBySlug = async (slug: string) =>
  request({
    method: "GET",
    url: `product/slug/${slug}/title`,
    params: {},
  }).then((response) => response);

export {
  getProducts,
  getProductById,
  searchProduct,
  getProductBySlug,
  getProductsOnSale,
  getProductTitleBySlug,
  fuzz,
};
