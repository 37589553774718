import request from "@/utils/request";

const subscribe = (email: string) =>
  request({
    method: "POST",
    url: "/subscribe",
    params: {},
    data: { email },
  }).then((response) => response);

const unsubscribe = (token: string) =>
  request({
    method: "DELETE",
    url: "/subscribe",
    params: {},
    data: { token },
  }).then((response) => response);

export { subscribe, unsubscribe };
