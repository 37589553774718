import { useState, useEffect } from "react";

/**
 * @description
 * Check whether it is client side render, to make sure window is defined
 * @return {boolean}
 */
export const checkCSR = (): boolean => typeof window !== "undefined";

/**
 * @description
 * Check whether it is on mobile device.
 * @return {boolean}
 */
export const useMobileCheck = (): boolean => {
  const [isMobile, setMobileState] = useState(false);

  useEffect(() => {
    const mobileCheck = window.matchMedia("(max-width: 768px)");
    setMobileState(mobileCheck.matches);

    const updateMobileState = (e: MediaQueryListEvent) =>
      setMobileState(e.matches);
    mobileCheck.addEventListener("change", updateMobileState);

    return () => mobileCheck.removeEventListener("change", updateMobileState);
  }, []);

  return isMobile;
};

/**
 * @description
 * Check whether it is a valid email address.
 * @return {boolean}
 */
export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
