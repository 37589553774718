import EStateAsyncStatus from "@/constants/stateAsyncState";
import { AppState } from "@/interfaces/redux";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ICustomerInfo from "@/interfaces/customer";
import { getCustomerInfo } from "@/service/customer";

export interface IInitialState {
  customerInfo: ICustomerInfo | null;
  status:
    | EStateAsyncStatus.failed
    | EStateAsyncStatus.idle
    | EStateAsyncStatus.pending
    | EStateAsyncStatus.succeeded;
}

const initialState: IInitialState = {
  customerInfo: null,
  status: EStateAsyncStatus.idle,
};

const fetchCustomer = createAsyncThunk("customer/fetchCustomer", async () => {
  const res = getCustomerInfo();
  return res;
});

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomer.pending, (state) => {
        state.status = EStateAsyncStatus.pending;
      })
      .addCase(fetchCustomer.fulfilled, (state, action) => {
        state.status = EStateAsyncStatus.succeeded;
        state.customerInfo = action.payload.data;
      })
      .addCase(fetchCustomer.rejected, (state) => {
        state.status = EStateAsyncStatus.failed;
      });
  },
});

export { fetchCustomer };
export const customer = (state: AppState) => state.customer;
export default customerSlice.reducer;
