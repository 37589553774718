import Link from "next/link";
import { AiFillShopping } from "react-icons/ai";
import { FaArrowRightLong } from "react-icons/fa6";

interface EmptyCartProps {
  setCartOpen: (value: boolean) => void;
}

const EmptyCart: React.FC<EmptyCartProps> = ({ setCartOpen }) => (
  <div className="flex items-center flex-col gap-4">
    <div className="w-20 h-20 rounded-full bg-gray-200 flex items-center justify-center">
      <AiFillShopping className="text-3xl" />
    </div>
    <p className="font-bold text-xl">Cart is Empty</p>
    <p className="text-center text-lg">
      Check out all the available products and buy some in the shop
    </p>
    <Link href="/category">
      <div
        className="bg-gray-950 flex px-6 py-3 gap-4 text-white hover:bg-icon rounded-md font-bold items-center "
        onClick={() => setCartOpen(false)}
      >
        Go Shopping
        <FaArrowRightLong />
      </div>
    </Link>
  </div>
);

export default EmptyCart;
