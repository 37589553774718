import { combineReducers, configureStore } from "@reduxjs/toolkit";
import categorySlice from "./slices/categorySlice";
import customerSlice from "./slices/customerSlice";
import cartSlice from "./slices/cartSlice";
import loginSlice from "./slices/loginSlice";
import blogSlice from "./slices/blogSlice";

const rootReducer = combineReducers({
  categories: categorySlice,
  customer: customerSlice,
  cart: cartSlice,
  login: loginSlice,
  blog: blogSlice,
});

const makeStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }),
  });
};

const store = makeStore();

export default store;
