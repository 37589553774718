import { useAppSelector } from "@/hooks/useRedux";
import { loginInfo } from "@/store/slices/loginSlice";
import Link from "next/link";
import { FiPhone } from "react-icons/fi";
import { MdOutlineContactSupport, MdOutlineDiscount } from "react-icons/md";

const Breadth = () => {
  const loginState = useAppSelector(loginInfo);
  return (
    <div className="bg-gray-950 w-full py-2">
      <div className="flex container px-2 justify-between mx-auto text-white text-sm">
        <div className="flex gap-4">
          <Link href="/sale">
            <div className="flex items-center gap-2">
              <MdOutlineDiscount className="text-lg" />
              <p className="hover:underline">Sale now on</p>
            </div>
          </Link>
          <div className="border-x border-gray-600 px-4">
            <Link href="/contact" className="flex items-center gap-2">
              <FiPhone className="text-lg" />
              <p className="hover:underline">
                Order by phone, chat / Visit our showroom
              </p>
            </Link>
          </div>
          <div className="flex items-center gap-2">
            <MdOutlineContactSupport className="text-lg" />
            <p>02 9698 3198 (8:30 - 18:00)</p>
          </div>
        </div>
        <div className="hidden sm:block">
          {!loginState.isLogin === true && (
            <Link href="/login">Log In / Sign Up</Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Breadth;
