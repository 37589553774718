import { categoryNav, helpNav } from "@/data/nav";
import Link from "next/link";
import { FaCcVisa } from "react-icons/fa";
import {
  FaCcAmex,
  FaCcMastercard,
  FaCcPaypal,
  FaGooglePay,
} from "react-icons/fa6";
import Subscription from "@/components/Shares/Subscription";
import { Image } from "@nextui-org/react";

const Footer = () => {
  const date = new Date();
  return (
    <footer className="bg-gray-100">
      <div className="container px-2 mx-auto">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2  lg:grid-cols-4 py-6 md:py-12 lg:py-16 border-b transition-all duration-500">
          <div>
            <p className="mb-2">We accept:</p>
            <div className="flex gap-3 text-3xl items-center">
              <FaCcVisa />
              <FaCcMastercard />
              <FaCcAmex />
              <Image
                src="/unionpay-4.svg"
                alt="unionpay"
                width={54}
                height={36}
                radius="none"
              />
              <FaCcPaypal />
              <Image
                src="/zippay.svg"
                alt="zippay"
                width={54}
                height={36}
                radius="none"
              />
            </div>
          </div>
          <div>
            <h3 className="text-xl font-bold">Get Help</h3>
            <nav>
              <ul className="flex flex-col gap-2 mt-4 list-none">
                {helpNav.map(({ label, link }, index) => {
                  return (
                    <li
                      key={index}
                      className="hover:text-icon transition-all duration-500"
                    >
                      <Link href={link}>{label}</Link>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
          <div>
            <h3 className="text-xl font-bold">Popular Categories</h3>
            <nav className="flex flex-col gap-2 mt-4">
              <ul className="list-none">
                {categoryNav.map(({ label, link }, index) => {
                  return (
                    <li
                      key={index}
                      className="hover:text-icon transition-all duration-500"
                    >
                      <Link href={link}>{label}</Link>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
          <div>
            <h3 className="text-xl font-bold">
              Keep up to date with our latest news and special offers.
            </h3>
            <div className="mt-6 mb-2">
              <Subscription />
            </div>
            <small>
              Enjoy a $30 discount on your first purchase over $199 (excluding
              shipping).
            </small>
          </div>
        </div>
        <div className="py-4">
          ©{date.getFullYear()} Priceworth Furniture. All rights reserved
        </div>
      </div>
    </footer>
  );
};

export default Footer;
