import EStateAsyncStatus from "@/constants/stateAsyncState";
import { AppState } from "@/interfaces/redux";
import { getBlogs } from "@/service/blog";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import IBlog from "@/interfaces/blog";

export interface IInitialState {
  blogsList: IBlog[];
  status:
    | EStateAsyncStatus.failed
    | EStateAsyncStatus.idle
    | EStateAsyncStatus.pending
    | EStateAsyncStatus.succeeded;
}

const initialState: IInitialState = {
  blogsList: [],
  status: EStateAsyncStatus.idle,
};

const fetchBlogs = createAsyncThunk("blogs/fetchBlogs", async () => {
  const res = getBlogs();
  return res;
});

const blogsSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlogs.pending, (state) => {
        state.status = EStateAsyncStatus.pending;
      })
      .addCase(fetchBlogs.fulfilled, (state, action) => {
        state.status = EStateAsyncStatus.succeeded;
        state.blogsList = action.payload.data;
      })
      .addCase(fetchBlogs.rejected, (state) => {
        state.status = EStateAsyncStatus.failed;
      });
  },
});

export { fetchBlogs };
export const blogs = (state: AppState) => state.blog;
export default blogsSlice.reducer;
