import Link from "next/link";
import { useEffect } from "react";
import { MdOutlineVerifiedUser } from "react-icons/md";
import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { faker } from "@faker-js/faker";
import { Image } from "@nextui-org/react";

const productData = [
  {
    id: 1,
    image:
      "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/2030336-5.jpg",
    name: "Madelina Velvet Sofa",
    link: "/product/madelina-sofa-rust-red",
  },
  {
    id: 2,
    image:
      "	https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/76a6fb75-6212-4ee8-bedb-5f6ae3b631b4.jpg",
    name: "Stella 3-Seater Sofa - Pink",
    link: "/product/stella-3-seater-sofa",
  },
  {
    id: 3,
    image:
      "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/6899bb07-21ed-493b-9772-3fbe7ebbb131.png",
    name: "Glen Coffee Table Set",
    link: "/product/glen-coffee-table-set",
  },
  {
    id: 4,
    image:
      "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/1030090-2.jpg",
    name: "Kiama Gas Lift Bed -Queen",
    link: "/product/kiama-gas-lift-bed-queen",
  },
  {
    id: 5,
    image:
      "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/1030143-4.jpg",
    name: "Thomas PU Bed with LED Light Queen Grey",
    link: "/product/thomas-pu-bed-with-led-light-queen",
  },
  {
    id: 6,
    image:
      "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/2020812-1.jpg",
    name: "Redfern Melamine 4 Door 2 Drawer with Mirror - White",
    link: "/product/redfern-melamine-4-door-2-drawer-with-mirror",
  },
  {
    id: 7,
    image:
      "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/3020116-6.png",
    name: "Bella Dining Chair",
    link: "/product/bella-dining-chair",
  },
  {
    id: 8,
    image:
      "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/4010073-4.png",
    name: "HEQS 334L Top Mount Fridge - White",
    link: "/product/heqs-360l-top-mount-fridge-freezer-white",
  },
  {
    id: 9,
    image:
      "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/3010139-2.jpg",
    name: "Zetland Computer Desk with Bookshelf DX-250",
    link: "/product/zetland-computer-desk-with-bookshelf-dx-250",
  },
  {
    id: 10,
    image:
      "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/3050196-2.png",
    name: "Fandi Ottoman",
    link: "/product/fandi-ottoman",
  },
];

const RecentPurchase = () => {
  useEffect(() => {
    const interval = setInterval(() => {
      const name = faker.person.firstName();
      const product =
        productData[Math.floor(Math.random() * productData.length)];

      toast.success(
        <div className="flex items-center gap-4">
          <Link href={product.link}>
            <div className="flex items-center justify-center w-24">
              <Image src={product.image} alt={product.name} radius="none" />
            </div>
          </Link>
          <div>
            <h4 className="text-sm">{name} purchased</h4>
            <div className="my-1">
              <Link
                href={product.link}
                className="hover:text-icon transition-all duration-500"
              >
                {product.name}
              </Link>
            </div>
            <div className="text-xs flex gap-1 items-center">
              <MdOutlineVerifiedUser className="text-green-500" />
              <span>Verified</span>
            </div>
          </div>
        </div>,
        {
          toastId: 1,
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: false,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        },
      );
    }, 120000);

    return () => clearInterval(interval);
  }, []);

  return null;
};

export default RecentPurchase;
